import React, { Component} from 'react'
import {Link, navigate, graphql} from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import {
	Container,
	Grid,
	Header,
	Image,
	Loader,
	Menu,
	Message,
	Pagination,
} from 'semantic-ui-react'
import ProductList from '../components/ProductList'
import MobileFilter from '../components/Filters/mobile'
import CategoryFilter from '../components/Filters/category'
import TextFilter from '../components/SearchBar/searchBarProductList'
import * as styles from './styles/template.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'

const Negosyo = require('../../lib/negosyo')

export default class Organization extends Component {
	state = {
		rating : undefined,
		products: undefined,
		masterProducts: undefined,
		shown:undefined,
		mobile:undefined,
		limit:20,
		currentPage:1,
		paginationRangeMaster:4,
		paginationRange:4,
		timeOut1:undefined,
		loadingProducts:true,
		category:null,
		categoryName:null,
		categoryLoading:undefined, //used for category mobile to show which category is being processed, undefined to differentiate from when user picks show all products
		searchText:"", //value of search bar
		activeText:null //value used in product search api for the searchtext, null
	};


	componentDidMount() {
		this.handleParameters(this.fetchData); //fetchData is called as a callback so the parameters for the search has been set before calling product search api
		this.handler();

		window.addEventListener('resize', this.resizeTimer);
	}

	handleParameters = (fetchData) => {
		const {location} = this.props
		if(location.search) {
			const parameters = location.search.split("&")
			/*important checks if current result is already matched,
			 *without this if statement, there'll be an infinite loop
			 */
			let page = 1;
			let category = null;
			let categoryName = null;
			let searchText = null
			for( let i =0; i < parameters.length; i++) {
				if(parameters[i].split("=")[0] === "page" || parameters[i].split("=")[0] === "?page") {
					page = parameters[i].split("=")[1]
				}else if(parameters[i].split("=")[0] === "category" || parameters[i].split("=")[0] === "?category") {
					category = parameters[i].split("=")[1]
					const data = this.props.data.negosyoNow.viewOrganization
					const filteredCategory = data.category.filter( (categoryItem)=>
						categoryItem.id===parameters[i].split("=")[1]
					)
					categoryName = filteredCategory.length > 0 ? filteredCategory[0].name:null
				} else  if(parameters[i].split("=")[0] === "search_query" || parameters[i].split("=")[0] === "?search_query") {
					searchText = decodeURIComponent( parameters[i].split("=")[1] )
				}else {
					//unplanned parameters
				}
			}
			this.setState({
				currentPage:page,
				category:category,
				categoryName:categoryName,
				searchText:searchText,
				activeText:searchText
			}, fetchData)


		}else {
			fetchData()
		}
	}

	resizeTimer = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimer);
	}

	handler = () => {
		//computer
		const data = this.props.data.negosyoNow.viewOrganization
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				shown: data.category.length > 0 ? 4:5,
				mobile:false,
				paginationRange:4
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown : 5,
				mobile:true,
				paginationRange:2
			})
		} else {  //mobile
			this.setState({
				shown : 2,
				mobile:true,
				paginationRange:1
			})
		}
	}
	/*
	componentDidUpdate(prevProps, prevState) {
		const data = this.props.data.negosyoNow.viewOrganization
		const {location} = this.props
		const {currentPage, searchText, category, activeText, categoryName } = this.state
		if(location.search!==prevProps.location.search) {
			const parameters = location.search.split("&");
			let searchTemp=undefined;
			let tempPage = undefined;
			let pageBool = false;
			let tempCategory = undefined
			let tempCategoryName = undefined
			//console.log(parameters.length)
			for( let i = 0; i<parameters.length;i++) {
				if( parameters[i].split("=")[0]==="?search_query" || parameters[i].split("=")[0]==="search_query" ) {
					if(searchTemp===undefined && activeText!==decodeURIComponent( parameters[i].split("=")[1] ) ) {
						//this.setState({
							//searchText:decodeURIComponent( parameters[i].split("=")[1] ),
							//loadingProducts:true,
							//activeText:decodeURIComponent( parameters[i].split("=")[1] )
						//})
						searchTemp=decodeURIComponent( parameters[i].split("=")[1] );
					}
				}else if (parameters[i].split("=")[0]==="?page" || parameters[i].split("=")[0]==="page") {
					if( currentPage!==parameters[i].split("=")[1] &&
							!isNaN(parameters[i].split("=")[1]) &&
							tempPage===undefined
					) {
						tempPage=parameters[i].split("=")[1]
						//this.setState({currentPage:parameters[i].split("=")[1]})
					}
					if( !isNaN(parameters[i].split("=")[1]) ) {
						pageBool = true;
					}
				}else if (parameters[i].split("=")[0]==="?category" || parameters[i].split("=")[0]==="category") {
					if( category!==parameters[i].split("=")[1] &&
							tempCategory===undefined
					) {
						tempCategory = parameters[i].split("=")[1]
						const filteredCategory = data.category.filter( (categoryItem)=>
							categoryItem.id===parameters[i].split("=")[1]
						)
						tempCategoryName = filteredCategory.length > 0 ? filteredCategory[0].name:null
						//this.setState({category:parameters[i].split("=")[1], loadingProducts:true})
					}



				}
			}
			//console.log(searchTemp)
			//console.log(tempCategory)
			const temp={
				currentPage:(tempPage===undefined ? currentPage:tempPage),
				activeText:searchTemp===undefined ? activeText:searchTemp,
				searchText:searchTemp===undefined ? searchText:searchTemp,
				category:tempCategory===undefined ? category:tempCategory,
				categoryName:tempCategoryName===undefined ? categoryName:tempCategoryName
			}
			if(pageBool===false && currentPage!==1) {

				temp.currentPage = 1
			}
			if(searchTemp===undefined && activeText!==null) {

				temp.activeText=null
				temp.searchText=null
			}
			if(tempCategory===undefined && category!==null) {
				temp.category=null
				temp.categoryName=null

			}
			this.setState(temp, ()=>{
				if(searchTemp!==undefined || tempCategory!==undefined) {
					const query = {
						organizationId:data.id
					}
					if(tempCategory) {
						query.categoryurl = tempCategory
					}
					if(searchTemp) {
						query.searchtext = searchTemp
					}
					//console.log(query)
					Negosyo.getProductSearchMultiple(query)
					.then((response)=>{
						this.setState({
							masterProducts:response,
							products:response,
							loadingProducts:false
						})
					}).catch((err)=>{
						this.setState({
							loadingProducts:false
						})
						//console.log(err)
					})


				}else {
					//not sure if this is right assumption
					if(category===null && activeText===null) {
						Negosyo.getProductSearchOrganization(data.id)
						.then((response) => {
							this.setState({
								masterProducts:response,
								products:response,
								loadingProducts:false
							})
						}).catch((err)=>{
							//console.log(err)
							this.setState({loadingProducts:false})
						})
					}

				}
			})

		}
	}
	*/
	fetchData = () => {
		const data = this.props.data.negosyoNow.viewOrganization
		const {
			category,
			activeText
		} = this.state

		if(
			category===null &&
			activeText===null
		) {
			Negosyo.getProductSearchOrganization(data.id)
			.then((response) => {
				if(Array.isArray(response) ) {
					this.setState({
						masterProducts:response,
						products:response,
						loadingProducts:false
					})
				}else {
					this.setState({
						masterProducts:[],
						products:[],
						loadingProducts:false
					})
				}
			}).catch((err)=>{
				this.setState({loadingProducts:false})
			})
		}else {
			const query = {
				organizationId:data.id
			}
			if(category) {
				query.categoryurl = category
			}
			if(activeText) {
				query.searchtext = activeText
			}
			Negosyo.getProductSearchMultiple(query)
			.then((response)=>{
				if(Array.isArray(response) ) {
					this.setState({
						masterProducts:response,
						products:response,
						loadingProducts:false
					})
				}else {
					this.setState({
						masterProducts:[],
						products:[],
						loadingProducts:false
					})
				}
			}).catch((err)=>{
				this.setState({
					loadingProducts:false
				})
			})
		}
	}


  changeCategory = (newCategory, categoryName) =>{

		this.setState({loadingProducts:true, categoryLoading:newCategory})
		const data = this.props.data.negosyoNow.viewOrganization

		if(newCategory===null) {
			Negosyo.getProductSearchOrganization(data.id)
			.then((response) => {
				if(Array.isArray(response) ) {
					this.setState({
						category:newCategory,
						categoryLoading:undefined,
						masterProducts:response,
						products:response,
						loadingProducts:false,
						currentPage:1,
						categoryName:null,
						searchText:"",
						activeText:""
					})
				}else {
					this.setState({
						category:newCategory,
						categoryLoading:undefined,
						masterProducts:[],
						products:[],
						loadingProducts:false,
						currentPage:1,
						categoryName:null,
						searchText:"",
						activeText:""
					})
				}

			}).catch((err)=>{
				this.setState({loadingProducts:false})
			})
		}else {
			const query = {
				categoryurl:newCategory,
				organizationId:data.id
			}
			Negosyo.getProductSearchMultiple(query)
			.then((response)=>{
				if(Array.isArray(response) ) {
					this.setState({
						category:newCategory,
						categoryLoading:undefined,
						masterProducts:response,
						products:response,
						loadingProducts:false,
						currentPage:1,
						categoryName:categoryName,
						searchText:"",
						activeText:""
					})
				}else {
					this.setState({
						category:newCategory,
						categoryLoading:undefined,
						masterProducts:[],
						products:[],
						loadingProducts:false,
						currentPage:1,
						categoryName:categoryName,
						searchText:"",
						activeText:""
					})
				}

			}).catch((err)=>{
				this.setState({
					loadingProducts:false
				})
			})
		}

	}

	changeSearchText = () =>{
		const {
			searchText
		} = this.state
		this.setState({loadingProducts:true})
		const data = this.props.data.negosyoNow.viewOrganization
		const {
			location
		} = this.props
		const {
			category
		} = this.state
		let url =""
		const query = {
			organizationId:data.id,
		}
		const encodedURI = encodeURIComponent(searchText)
		if(searchText!=="") {
			url +=`?search_query=${encodedURI}`
			query.searchtext=searchText

		}
		if(category!==null) {
			query.categoryurl = category
			if(url!=="") {
				url +=("&category=" + category)
			}else {
				url +=("?category=" + category)
			}
		}
		navigate(`${location.pathname}${url}#Products`)

		if(searchText!=="" || category!==null) {
			Negosyo.getProductSearchMultiple(query)
			.then((response)=>{
				if(Array.isArray(response) ) {
					this.setState({
						masterProducts:response,
						products:response,
						loadingProducts:false,
						currentPage:1,
						activeText:searchText
					})
				}else {
					this.setState({
						masterProducts:[],
						products:[],
						loadingProducts:false,
						currentPage:1,
						activeText:searchText
					})
				}

			}).catch((err)=>{
				this.setState({
					loadingProducts:false
				})
			})
		}else {
			Negosyo.getProductSearchOrganization(data.id)
			.then((response) => {
				if(Array.isArray(response) ) {
					this.setState({
						masterProducts:response,
						products:response,
						loadingProducts:false,
						currentPage:1,
						activeText:""
					})
				}else {
					this.setState({
						masterProducts:[],
						products:[],
						loadingProducts:false,
						currentPage:1,
						activeText:""
					})
				}

			}).catch((err)=>{
				this.setState({loadingProducts:false})
			})
		}

	}

	handleSearchChange = (e, { value }) => {
		this.setState({  searchText:value })
	}


	render() {
		const location = this.props.location
		const {
			products,
			shown,
			mobile,
			currentPage,
			limit,
			paginationRange,
			loadingProducts,
			category,
			categoryName,
			categoryLoading,
			searchText,
			activeText
		} =this.state
		const {
			changeCategory,
			changeSearchText,
			handleSearchChange
		} = this
		let slicedProducts
		let length = 0
		let totalPages;
		if(products) {
			slicedProducts = products
			length = slicedProducts.length
			totalPages=Math.ceil(length/limit)
			slicedProducts = slicedProducts.slice( (currentPage-1)*limit, limit*currentPage)
		}
		const data = this.props.data.negosyoNow.viewOrganization
		const { name, mainImageHref, url, summary } = data
		let parameters =""
		if( category!==null && category!=="") {
			parameters +=( "category=" + category + "&")
		}
		if(activeText!==null && activeText!=="") {
			parameters +=( "search_query=" + activeText + "&")
		}
		let subHeader = ""
		if(loadingProducts) {
			subHeader="Please standby ..."
		}
		else if(length) {
			if(activeText) {
				subHeader=`${length} Products found for '${activeText}'`
			}else {
				subHeader=`${length} Products under ${categoryName}`
			}
		}

		return (
			<Layout location={location} backButton={true}>
				<SEO title={name}
					canonicalpath={"/organization/"+url}
					description={summary}
					image={mainImageHref} imageWidth={1200} imageHeight={480}
				/>

				<Container >
					{ mainImageHref &&
						<Image
							src={mainImageHref}
							className={styles.banner}
						/>
					}
					<span id="Products" className={`${styles.anchor} ${styles.test}`} />
					<Grid className={styles.container}>

						{ mobile===true && (
							<Grid.Column width={16}>
								<MobileFilter
									filter={{
										category:{
											currentCategory:category,
											categoryList:data.category,
											categoryLoading:categoryLoading,
											pickCategory:changeCategory,
											location:location
										},
										text:{
											handleSubmit:changeSearchText,
											value:searchText,
											handleSearchChange:handleSearchChange,
											placeholder:category===null ? `Search ${name}`:`Search ${categoryName} in ${name}`
										}
									}}
									loading={loadingProducts}

								/>
							</Grid.Column>
						)}
						{ mobile===false && (
							<React.Fragment>
								<Grid.Column width={16}>
									<Menu borderless>

										<Menu.Item position="right" >
											<TextFilter
												handleSubmit={changeSearchText}
												value={searchText}
												handleSearchChange={handleSearchChange}
												placeholder={category===null ? `Search ${name}`:`Search ${categoryName} in ${name}`}
												loading={loadingProducts}
											/>
										</Menu.Item>
									</Menu>
								</Grid.Column>
								<Grid.Column width={4}>
									<CategoryFilter
										categoryList={data.category}
										location={location}
										currentCategory={category}
										handleClick={changeCategory}
										header={"Categories"}
									/>
								</Grid.Column>
							</React.Fragment>
						)}

						<Grid.Column
							width={ (mobile===false && data.category.length>0) ? 12:16}
						>

							<Header
								className={`${styles.header} ${styles.centerText}`}
								content={category===null ? <h1>{name}</h1>:<h1>{`${categoryName} in ${name}`}</h1>}
							/>

							{ !loadingProducts  ? (
								<React.Fragment>
									{ (categoryName || activeText) && (
										<div className={`${styles.centerText} ${styles.text}`}>
											{subHeader!=="" && (
												<React.Fragment>
													{subHeader}
													<br/>
												</React.Fragment>
											)}
											{ activeText &&
												<div className={styles.italicText+" "+styles.msg} >
													Cannot find what you're looking for?&nbsp;
													<Link
														to={"/request-product/"}
														state={{
															productName:activeText
														}}
													>
														Let us know.
													</Link>
												</div>
											}
											<br/>


										</div>
									)}

									{slicedProducts!==undefined && (
										<React.Fragment>
											{ products.length === 0 && (
												<Message negative className={`${styles.msg} ${styles.centerText}`}>
													<Message.Header>
														No Products found
													</Message.Header>
												</Message>
											)}
											{ slicedProducts.length > 0 && (
												<ProductList
													products={slicedProducts}
													shown={shown}
													grid={(mobile===false && data.category.length>0) ? 12:16}
													cameFrom={`organization-${name}`}
													page={currentPage}
													offset={20}
													listName={`Category-${name}`}
												/>
											)}

										</React.Fragment>
									)}

									{ totalPages > 1 && (
										<React.Fragment>
											<br/>
											<Pagination
												activePage={currentPage}
												onPageChange={(e, {activePage})=>{
													this.setState({currentPage:activePage})
													navigate(`${location.pathname}?${parameters}page=${activePage}#Products`)
												}}
												ellipsisItem={null}
												boundaryRange={null}
												siblingRange={paginationRange}
												totalPages={totalPages}
												key="Pagination"
												pageItem={{
													className:styles.paginationItem
												}}
												firstItem={{
													className:styles.paginationItem,
													content:"<<"
												}}
												lastItem={{
													className:styles.paginationItem,
													content:">>"
												}}
												nextItem={{
													className:styles.paginationItem,
													content:">"
												}}
												prevItem={{
													className:styles.paginationItem,
													content:"<"
												}}
											/>
										</React.Fragment>
									)}

								</React.Fragment>
							) : (
								<Loader active inline="centered" key={"loader"}/>
							)}

						</Grid.Column>
					</Grid>
				</Container>

			</Layout>
		)
	}
}

export const data = graphql`
	query organizationQuery($url:String!) {
		negosyoNow {
			viewOrganization( url: $url ) {
				id
				url
				summary
				mainImageHref
				name
				category {
					name
					id
				}
			}
		}
	}
`



