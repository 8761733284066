// extracted by mini-css-extract-plugin
export var categoryFilter = "filters-module--categoryFilter--d0768";
export var closeButton = "filters-module--closeButton--5b3cf";
export var color = "filters-module--color--40072";
export var div = "filters-module--div--7692a";
export var form = "filters-module--form--0eb99";
export var header = "filters-module--header--e91f1";
export var icon = "filters-module--icon--598d2";
export var mobileCategoryButton = "filters-module--mobileCategoryButton--44e55";
export var portalSegment = "filters-module--portalSegment--f3f1c";
export var text = "filters-module--text--e89f9";