import React, { Component } from 'react'
import {Header} from 'semantic-ui-react'
import {Link} from 'gatsby'
import * as styles from './styles/filters.module.css'


class CategoryFilter extends Component {

	render()
	{
		const {
			categoryList,
			location,
			handleClick,
			header,
			currentCategory
		} = this.props
		if(categoryList===undefined) {
			return (
			 ""
			)
		}
		if(categoryList.length===0) {
			return (
			 ""
			)
		}
		return(
			<React.Fragment>
				<Header className={styles.header}>
					{header}
				</Header>
				<div
					className={`${styles.categoryFilter}`}
				>
					{currentCategory!==null && (
						<React.Fragment>
							<Link
								to={`${location.pathname}#Products`}
								className={styles.text}
								onClick={()=>{
									handleClick(null, null)
								}}
							>
								Show All Products
							</Link>
							<br/>
						</React.Fragment>
					)}

					{categoryList.map( (category)=> {
						if(category.id===currentCategory) {
							return (
								<React.Fragment key={category.id}>
									{category.name}
									<br/>
								</React.Fragment>
							)
						}
						return (
							<React.Fragment key={category.id}>
								<Link
									to={`${location.pathname}?category=${category.id}#Products`}
									className={styles.text}
									title={category.url}
									onClick={()=>{
										handleClick(category.id, category.name)
									}}
								>
									{category.name}
								</Link>
								<br/>
							</React.Fragment>
						)
					})}
				</div>
			</React.Fragment>

		)
	}
}
export default CategoryFilter
