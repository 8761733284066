import React, { Component } from 'react'
import { Header, Icon, Button, Menu, Portal, Segment, Divider } from 'semantic-ui-react'
//import {Link} from 'gatsby'
import CategoryMobileFilter from './categoryMobile'
import TextFilter from '../SearchBar/searchBarProductList'
import * as styles from './styles/filters.module.css'

/*should rename this file and class to be more clear its a holder of filters for category/organization page*/
class MobileFilter extends Component {
	state = {
		visible:false
	}

	handleClose = ()=> {
		this.setState({visible:false})
	}
	handleOpen = () =>{
		this.setState({visible:true})
	}
	render() {
		const {
			filter,
			loading
		}=this.props
		const {
			visible
		} = this.state
		const {
			handleClose,
			handleOpen
		} = this
		let showFilter = false //adjust this if we add more filters to mobile
		if(filter.category.categoryList) {
			if(filter.category.categoryList.length > 0) {
				showFilter= true
			}
		}
		return (
			<Menu>
				<Menu.Menu position="right">
					<Menu.Item className={"borderless"}>
						<TextFilter {...filter.text} loading={loading}/>
					</Menu.Item>
					{ showFilter && (
						<Menu.Item onClick={handleOpen}>
							<Icon
								name="filter"
								className={styles.color}
							/>
						</Menu.Item>
					)}



				</Menu.Menu>
				<Portal open={visible}>
					<Segment className={styles.portalSegment}>
						<Button
							aria-label="Close Navigation"
							basic
							circular
							onClick={handleClose}
							className={styles.closeButton}
						>
							X
						</Button>
						<div className={styles.div}>
							<Header className={styles.header}>
								Filter
							</Header>

							<CategoryMobileFilter {...filter.category} loading={loading}/>
							<Divider/>
						</div>
					</Segment>
				</Portal>
			</Menu>
		)
	}
}

export default MobileFilter

