import React, { Component } from 'react'
//import {Link, navigate} from 'gatsby'
import { Search } from 'semantic-ui-react'
import * as styles from './styles/searchBar.module.css'
import {searchBarColor} from '../../globalVar'

//const Negosyo = require('../../../lib/negosyo')

const initialState = { isLoading: false, results: [], value: '' }

export default class SearchBar extends Component {
	state = {
		...initialState,
		value:this.props.value
	}


	handleKeyPress = (event) => {
		if(event.key==="Enter") {
			this.props.handleSubmit()
		}
	}

	handleButtonClick = (event) => {

		this.props.handleSubmit()
	}
	/*
	mapNegosyoResults = ({name, mainImageHref, description, meta, id}) => {
		return (
			{
				as:Link,
				to:`/product/${id}/`,
				title:name,
				image:mainImageHref,
				description:description.length > 50 ? `${description.substr(0,50)}...`:description,
				price:`${meta.display_price.with_tax.currency}${meta.display_price.with_tax.amount}`,
			}

		)
	}*/

	render() {
		const {
			isLoading,
			//value,
			results
		} = this.state
		return (
			<Search
				input={{
					className:styles.inputProductList,
					input:<input type="text" ></input>,
					action:{
						color:searchBarColor,
						icon:'search',
						loading:isLoading,
						onClick:this.handleButtonClick
					},
					placeholder:this.props.placeholder
				}}
				icon={{
					name:'',
				}}
				onSearchChange={this.props.handleSearchChange}
				results={results}
				value={this.props.value}
				className={styles.input}
				onKeyPress={this.handleKeyPress}
				showNoResults={false}
			/>
		)
	}
}
