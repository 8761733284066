import React, { Component } from 'react'
import { Header, Button, Segment, Grid } from 'semantic-ui-react'
import {navigate} from 'gatsby'
import * as styles from './styles/filters.module.css'
import {
	confirmationColor
} from '../../globalVar'


class CategoryFilter extends Component {

	render() {
		const {
			categoryList,
			categoryLoading,
			currentCategory,
			location,
			pickCategory,
			loading
		} = this.props
		if(categoryList===undefined) {
			return (
				""
			)
		}
		if(categoryList.length===0) {
			return (
				""
			)
		}
		return (
			<React.Fragment>
				<Segment>
					<Header>
						Categories
					</Header>
					<Grid>
						<Grid.Column tablet={5} mobile={8}>
							<Button
								color={currentCategory===null ? confirmationColor:""}
								className={styles.mobileCategoryButton}
								onClick={()=>{
									navigate(`${location.pathname}#Products`)
									pickCategory(null, null)
								}}
								disabled={loading}
								loading={categoryLoading===null}
							>
								Show All Products
							</Button>
						</Grid.Column>
						{ categoryList.map((category)=>{
							return (
								<Grid.Column tablet={5} mobile={8}>
									<Button
										color={currentCategory===category.id ? confirmationColor:""}
										className={styles.mobileCategoryButton}
										onClick={()=>{
											navigate(`${location.pathname}?category=${category.id}#Products`)
											pickCategory(category.id, category.name)
										}}
										disabled={loading}
										loading={categoryLoading===category.id}
									>
										{category.name}
									</Button>
								</Grid.Column >
							)
						})}
					</Grid>
				</Segment>
			</React.Fragment>
		)
	}
}

export default CategoryFilter

